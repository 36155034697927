@use '../scss/utilities/navlink';

.Nav {
  ul {
    display: flex;
    gap: var(--cui-spacing-40);
    list-style: none;
    padding-left: 0;

    li:hover {
      --link-color: var(--cui-clr-primary-400);
    }
  }
}

.link {
  @extend .u-navlink;
}
