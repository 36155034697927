.u-navlink {
  position: relative;
  color: var(--link-color, inherit);
  text-decoration: none;

  &:hover {
    --link-color: var(--cui-clr-primary-400);
  }

  &::after {
    content: '';
    position: absolute;
    height: 0.125rem;
    width: 100%;
    background-color: currentColor;
    bottom: -0.25em;
    left: 0;
    transform: scale(0);
    transition: transform 150ms ease;
  }

  &[aria-current='page'] {
    font-weight: bold;
    --link-color: initial;

    &::after {
      transform: scale(1);
    }
  }
}
