@use '~@coopbetala/coop-digital-react-ui/dist/scss/base';
@use './scss/base/font-face';

html {
  font-family: 'Akkurat', sans-serif;
}

h1,
h2,
h3 {
  font-family: 'CoopNew', sans-serif;
}

h1 {
  font-size: clamp(1.25rem, 1vw + 1rem, 1.75rem);
  text-align: center;
}
