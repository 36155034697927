@use './scss/utilities/container';
@use './scss/utilities/navlink';

.App {
  &Header {
    background-color: var(--cui-clr-primary-600);
    color: white;
    height: 3.75rem;

    button {
      justify-self: flex-end;
    }

    &Container {
      @extend .u-container;
      display: grid;
      grid-template-columns: 1fr auto 1fr;
      align-items: center;
    }
  }

  &Brand {
    @extend .u-navlink;
    justify-self: start;
  }

  &Container {
    padding-top: var(--cui-spacing-40);
    padding-bottom: var(--cui-spacing-40);
  }
}
