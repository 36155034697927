@font-face {
  font-family: 'Akkurat';
  src: url('../../assets/fonts/Akkurat-Normal.woff2') format('woff2'),
    url('../../assets/fonts/Akkurat-Normal.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Akkurat';
  src: url('../../assets/fonts/Akkurat-Fett.woff2') format('woff2'),
    url('../../assets/fonts/Akkurat-Fett.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'Akkurat';
  src: url('../../assets/fonts/Akkurat-FettKursiv.woff2') format('woff2'),
    url('../../assets/fonts/Akkurat-FettKursiv.woff') format('woff');
  font-weight: bold;
  font-style: italic;
}
@font-face {
  font-family: 'Akkurat';
  src: url('../../assets/fonts/Akkurat-Leicht.woff2') format('woff2'),
    url('../../assets/fonts/Akkurat-Leicht.woff') format('woff');
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: 'Akkurat';
  src: url('../../assets/fonts/Akkurat-LeichtKursiv.woff2') format('woff2'),
    url('../../assets/fonts/Akkurat-LeichtKursiv.woff') format('woff');
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'CoopNew';
  src: url('../../assets/fonts/CoopNew-Regular.woff2') format('woff2'),
    url('../../assets/fonts/CoopNew-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'CoopNew';
  src: url('../../assets/fonts/CoopNew-Black.woff2') format('woff2'),
    url('../../assets/fonts/CoopNew-Black.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}
